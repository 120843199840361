@import url(https://fonts.googleapis.com/css2?family=Bad+Script&family=Bangers&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,600;1,700;1,800;1,900&display=swap);
body {
	margin: 0;
	background-color: black;
	overflow-x: hidden;
}
html {
	scroll-behavior: smooth !important;
}
*:focus {
	border-color: inherit !important;
	box-shadow: none !important;
}
.pointer {
	cursor: pointer;
}
/* width */
::-webkit-scrollbar {
	width: 8px;
	border-radius: 24px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #212121;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #fee53c;
	border-radius: 24px;
	cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #fee53c;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

@font-face {
	font-family: 'Montserrat-Bold';
	src: url(/static/media/Montserrat-Bold.63fae500.ttf);
}
@font-face {
	font-family: 'Montserrat-Regular';
	src: url(/static/media/Montserrat-Regular.ac98a5f5.ttf);
}
.start-40 {
	left: 40% !important;
}
.start-45 {
	left: 45% !important;
}
.font-100 {
	font-weight: 100;
}
.font-200 {
	font-weight: 200;
}
.font-300 {
	font-weight: 300;
}
.font-300 {
	font-weight: 400;
}
.font-500 {
	font-weight: 500;
}
.font-600 {
	font-weight: 600;
}
.font-700 {
	font-weight: 700;
}
.font-title {
	font-size: 25px !important;
}
.font-subtitle {
	font-size: 25px !important;
}
.text__primary {
	color: #fee53c !important;
}
.text__secondary {
	color: #ffaf00 !important;
}
.bg__primary {
	background-color: #fee53c !important;
}
.Bad-Script {
	font-family: 'Bad Script';
}
.Bangers {
	font-family: 'Bangers';
}
.Mont-Regular {
	font-family: 'Montserrat-Regular';
}
.App {
	background: #212121;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
	opacity: 1 !important;
}
.navbar {
	z-index: 1;
}
.nav-item .nav-link {
	text-decoration: none !important;
	color: black !important;
	font-family: 'Montserrat-Bold' !important;
	font-size: 20px;
	cursor: pointer;
}
.header {
	background: url(/static/media/hero.e7de48a7.png);
	background-position: 170% 50% !important;
	background-repeat: no-repeat;
	background-size: 1300px;
}
@media (max-width: 1367.98px) {
	.header {
		background-position: 180% -80% !important;
		background-size: 1100px;
	}
}
@media (max-width: 1308.98px) {
	.header {
		background-position: 170% -70% !important;
		background-size: 1050px;
	}
}
@media (max-width: 1262.98px) {
	.header {
		background-position: 150% -60% !important;
		background-size: 1000px;
	}
}
@media (max-width: 1208.98px) {
	.header {
		background-position: 160% -30% !important;
		background-size: 900px;
	}
}
@media (max-width: 1098.98px) {
	.header {
		background-position: 160% -70% !important;
		background-size: 870px;
	}
}
@media (max-width: 1098.98px) {
	.header {
		background-position: 160% -70% !important;
		background-size: 870px;
	}
}
@media (max-width: 997.98px) {
	.header {
		background-position: 300% -70% !important;
		background-size: 870px;
	}
}
@media (max-width: 959.98px) {
	.header {
		background-position: 450% -70% !important;
		background-size: 870px;
	}
}
@media (max-width: 937.98px) {
	.header {
		background-position: 600% -70% !important;
		background-size: 870px;
	}
}
@media (max-width: 919.98px) {
	.header {
		background-position: 800% -70% !important;
		background-size: 870px;
	}
}
@media (max-width: 909.98px) {
	.header {
		background-position: 500% -40% !important;
		background-size: 840px;
	}
}
@media (max-width: 898.98px) {
	.header {
		background-position: 450% -30% !important;
		background-size: 810px;
	}
}
@media (max-width: 869.98px) {
	.header {
		background-position: 650% -30% !important;
		background-size: 810px;
	}
}
@media (max-width: 855.98px) {
	.header {
		background-position: 490% -30% !important;
		background-size: 790px;
	}
}
@media (max-width: 837.98px) {
	.header {
		background-position: 800% -30% !important;
		background-size: 790px;
	}
}
@media (max-width: 827.98px) {
	.header {
		background-position: 1100% -30% !important;
		background-size: 790px;
	}
}
@media (max-width: 810.98px) {
	.header {
		background-position: 3100% -30% !important;
		background-size: 790px;
	}
}
@media (max-width: 810.98px) {
	.header {
		background-position: 45% -190% !important;
		background-size: 990px;
	}
}
@media (max-width: 768.98px) {
	.header {
		background-position: 50% -65% !important;
		background-size: 870px;
	}
	.navbar {
		background: #fee53c !important;
	}
}
@media (max-width: 540.98px) {
	.header {
		background-position: 45% -15% !important;
		background-size: 650px;
	}
	.next__thing {
		margin-top: 490px !important;
	}
}
@media (max-width: 380.98px) {
	.header {
		background-position: 52% -4% !important;
		background-size: 450px;
	}
	.next__thing {
		margin-top: 350px !important;
	}
}
.hero__img {
	position: absolute;
	height: 1300px;
	width: 1300px;
	right: -250px;
	margin-top: -280px;
	overflow: hidden !important;
}

.logo {
	width: 300px;
	height: 90px;
}
.logo_small {
	height: 50px;
}
.btn__mint {
	background-color: black !important;
	padding: 6px 30px !important;
	font-family: 'Montserrat-Bold' !important;
	border-radius: 0 !important;
	border: 0 !important;
}
.next__thing {
	margin-top: 50px;
	font-size: 106px;
	font-family: 'Bad Script';
	font-weight: 600;
	color: white;
	position: absolute;
	text-shadow: 6px 2px 8px #212121;
}
.bapes {
	font-size: 278px;
	color: #fee53c !important;
	font-family: 'Bangers';
	position: absolute;
	margin-top: 300px;
	text-shadow: 6px 2px 8px #212121;
}
.about__us {
	margin-top: 600px;
	font-family: 'Bangers';
	color: white;
	font-size: 50px;
}
.intro {
	position: absolute;
	font-family: 'Montserrat-Regular' !important;
	color: white;
	font-size: 42px;
}
.unique__bapes {
	margin-top: 650px;
	margin-left: 120px;
}
.unique__text {
	font-size: 128px;
}
.unique__number {
	font-size: 178px;
}
.bape__card {
	height: 380px;
}
.bape__card img {
	border: 1px solid #fee53c;
	height: 100%;
	object-fit: cover;
	object-position: center center;
}
.roadmap__section {
	margin-top: 60px;
	/* background-color: #fee53c; */
	background: url(/static/media/roadmap_bg.7359c6db.png);
	background-position: cover;
}
.road_map {
	font-size: 165px;
	color: black;
}
.road__map__card {
	background-color: black;
}
.road_map__phase {
	color: #fee53c;
	font-size: 49.71px;
	font-family: 'Montserrat-Bold';
}
.road_map__subtitle {
	font-family: 'Montserrat-Bold';
	font-size: 30.21px !important;
	color: white;
}
.road_map__desc {
	font-family: 'Montserrat-Regular';
	font-size: 24.17px !important;
	color: white;
}
.road_map__number {
	font-family: 'Montserrat-Bold';
	font-size: 37.1px !important;
	color: #fee53c;
	font-weight: bold;
}
.border2 {
	border: 2px solid #fee53c;
}
.team__card {
	cursor: pointer;
}
.team__card img {
	width: 100% !important;
}
.hover__info {
	background-color: #fee53c;
	padding: 20px;
}
.hover__info p {
	font-family: 'Montserrat-Regular' !important;
	font-size: 18px;
}
/* .team__card:hover {
	transform: scale(1.02);
	transition: 0.5s;
} */
/* .team__card:hover .hover__info {
	display: block;
}
.team__card:hover .normal__info {
	display: none;
} */
.custom-modal-content {
	border-radius: 0px !important;
}
.custom-modal-body {
	border-radius: 0px !important;
	padding: 10px !important;
}
.modal-content {
	border-radius: 20px !important;
}
.modal-body {
	border-radius: 20px !important;
	padding: 20px !important;
}
.btn__cancel {
	position: absolute;
	top: -30px;
	right: -20px;
}
.custom__input {
	border-radius: 20px !important;
	border: 2px solid #fee53c !important;
	background-color: transparent !important;
	color: white !important;
	text-align: right;
	font-size: 25px !important;
	font-family: 'Bangers';
}
.vertical-timeline::before {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAbECAYAAAAw/g7zAAAABHNCSVQICAgIfAhkiAAAAedJREFUeF7t18tqQlEUBNHr/390FPPAQSKBRuoKK5CRE2l2VR0vx3F83P5//bv48DiMcLsCI3yN8Bcq94VO9yGyGQy8dywZjME+/ewSjOASft4q74fD6Z5ZT7+QN5g3mOgo77ckTvlDkcFIiqRI6lFSTx8u3mCEQRiE8W9hnO5nEIMxGIMxGIM9mPCFP05PFwBfSAAEIA2ANxgEIZgiqIITggw2zef6zCcAaQAYDIIQTBFUwQlBBpvmc33mE4A0AAwGQQimCKrghCCDTfO5PvMJQBoABoMgBFMEVXBCkMGm+Vyf+QQgDQCDQRCCKYIqOCHIYNN8rs98ApAGgMEgCMEUQRWcEGSwaT7XZz4BSAPAYBCEYIqgCk4IMtg0n+sznwCkAWAwCEIwRVAFJwQZbJrP9ZlPANIAMBgEIZgiqIITggw2zef6zCcAaQAYDIIQTBFUwQlBBpvmc33mE4A0AAwGQQimCKrghCCDTfO5PvMJQBoABoMgBFMEVXBCkMGm+Vyf+QQgDQCDQRCCKYIqOCHIYNN8rs98ApAGgMEgCMEUQRWcEGSwaT7XZz4BSAPAYBCEYIqgCk4IMtg0n+sznwCkAWAwCEIwRVAFJwQZbJrP9ZlPANIAMBgEIZgiqIITggw2zef6gvmuSPbPQpdjxkkAAAAASUVORK5CYII=) !important;
}
.vertical-timeline-element-content {
	box-shadow: none !important;
	background-color: black !important;
	border-radius: 36px !important;
	color: white;
}
.vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
	top: 60px !important;
	height: 20px !important;
	border: 17px solid transparent !important ;
	border-right: 37px solid black !important ;
}
.vertical-timeline-element-icon {
	box-shadow: none !important;
	height: 25px !important;
	width: 25px !important;
	left: 51.75% !important;
	top: 65px !important;
}
.react-multi-carousel-list {
	height: 600px !important;
}
.react-multiple-carousel__arrow {
	display: none !important;
}
.react-multi-carousel-dot button {
	border: 2px solid #fee53c !important;
	background-color: #212121 !important;
}
.react-multi-carousel-dot--active button {
	background-color: #fee53c !important;
}
@media (min-width: 1170.98px) {
	.vertical-timeline-element:nth-child(2) {
		margin-top: -450px !important;
	}
	.vertical-timeline-element:nth-child(3) {
		margin-top: -200px !important;
	}
	.vertical-timeline-element:nth-child(4) {
		margin-top: -100px !important;
	}
	.vertical-timeline-element:nth-child(5) {
		margin-top: -330px !important;
	}
	.vertical-timeline-element:nth-child(6) {
		margin-top: -50px !important;
	}
}
@media (max-width: 1170px) {
	.vertical-timeline-element-icon {
		left: 1% !important;
	}
}
.the__team {
	font-size: 250px !important;
}
@media (min-width: 1777.8px) {
	.react-multi-carousel-list {
		height: 700px !important;
	}
	.react-multi-carousel-dot button {
		height: 20px !important;
		width: 20px !important;
	}
	.container {
		min-width: 95vw !important;
	}
	.header {
		background-position: 120% -80% !important;
		background-repeat: no-repeat;
		background-size: auto;
	}
	.hero__img {
		height: 1600px;
		width: 1800px;
		right: -310px;
		margin-top: -290px;
	}
	.bape__card {
		height: 500px !important;
		/* width: 400px !important; */
	}
	.logo {
		width: 200px;
		height: 60px;
	}
	.nav-item .nav-link {
		font-size: 26px;
	}
	.next__thing {
		font-size: 180px;
	}
	.bapes {
		margin-top: 440px;
		font-size: 550px;
	}
	.about__us {
		margin-top: 1000px;
		font-size: 160px;
	}
	.intro {
		position: relative;
		font-size: 60px;
	}
	.unique__bapes {
		margin-left: 50px;
		margin-top: 150px;
	}
	.unique__number {
		font-size: 250px;
	}
	.unique__text {
		font-size: 250px;
	}
	.road_map {
		font-size: 160px;
	}
	.vertical-timeline-element:nth-child(2) {
		margin-top: -450px !important;
	}
	.vertical-timeline-element:nth-child(3) {
		margin-top: -280px !important;
	}
	.vertical-timeline-element:nth-child(4) {
		margin-top: -220px !important;
	}
	.vertical-timeline-element:nth-child(5) {
		margin-top: -330px !important;
	}
	.vertical-timeline-element:nth-child(6) {
		margin-top: -50px !important;
	}
	.road_map__phase {
		font-size: 40px;
	}
	.road_map__subtitle {
		font-size: 24px !important;
	}
	.road_map__desc {
		font-size: 16px !important;
	}
	.road_map__number {
		font-size: 20px !important;
	}
	.the__team {
		font-size: 160px !important;
	}
	.hover__info p {
		font-family: 'Montserrat-Regular';
		font-size: 26px;
	}
}
@media (max-width: 1460.8px) {
	.hero__img {
		height: 1200px;
		width: 1400px;
		right: -310px;
		margin-top: -290px;
	}
	.logo {
		width: 200px;
		height: 60px;
	}
	.nav-item .nav-link {
		font-size: 26px;
	}
	.next__thing {
		font-size: 100px;
	}
	.bapes {
		margin-top: 320px;
		font-size: 230px;
	}
	.about__us {
		margin-top: 600px;
		font-size: 100px;
	}
	.intro {
		position: relative;
		font-size: 40px;
	}
	.unique__bapes {
		margin-left: 50px;
		margin-top: 150px;
	}
	.unique__number {
		font-size: 150px;
	}
	.unique__text {
		font-size: 150px;
	}
	.road_map {
		font-size: 160px;
	}
	.road_map__phase {
		font-size: 40px;
	}
	.road_map__subtitle {
		font-size: 24px !important;
	}
	.road_map__desc {
		font-size: 16px !important;
	}
	.road_map__number {
		font-size: 20px !important;
	}
	.the__team {
		font-size: 160px !important;
	}
	.hover__info p {
		font-family: 'Montserrat-Regular';
		font-size: 26px;
	}
}
@media (max-width: 1390.8px) {
	/* .container {
		min-width: 95vw !important;
	} */
	.hero__img {
		height: 1100px;
		width: 1200px;
		right: -310px;
		margin-top: -290px;
	}
	.bapes {
		margin-top: 300px;
		font-size: 270px;
	}
}
@media (max-width: 1241px) {
	.hero__img {
		height: 1100px;
		width: 1100px;
	}
	.logo {
		width: 250px;
		height: 70px;
	}
	.btn__mint {
		padding: 6px 30px !important;
	}
}
@media (max-width: 1098px) {
	.hero__img {
		height: 1000px;
		width: 1000px;
	}
	.logo {
		width: 200px;
		height: 60px;
	}
	.nav-item .nav-link {
		font-size: 16px;
	}
	.next__thing {
		font-size: 80px;
	}
	.bapes {
		margin-top: 250px;
		font-size: 200px;
	}
	.about__us {
		margin-top: 500px;
	}
	.intro {
		position: relative;
		font-size: 30px;
	}
	.unique__bapes {
		margin-left: 50px;
		margin-top: 150px;
	}
	.unique__number {
		font-size: 90px;
	}
	.unique__text {
		font-size: 90px;
	}
	.road_map {
		font-size: 120px;
	}
	.road_map__phase {
		font-size: 40px;
	}
	.road_map__subtitle {
		font-size: 24px !important;
	}
	.road_map__desc {
		font-size: 16px !important;
	}
	.road_map__number {
		font-size: 20px !important;
	}
	.the__team {
		font-size: 120px !important;
	}
}
@media (max-width: 767px) {
	.hero__img {
		overflow: hidden;
		margin-top: -200px;
		left: -70px;
		height: 850px;
		width: 900px;
	}
	.navbar {
		background-color: #ffea5e !important;
	}
	.next__thing {
		margin-top: 650px;
		font-size: 50px;
		text-align: center;
		position: relative;
	}
	.bapes {
		text-align: center;
		margin-top: 0;
		position: relative;
		font-size: 200px;
	}
	/* .bape__card {
		width: 300px !important;
		height: 350px !important;
	} */
	.react-multi-carousel-track {
		padding: auto !important;
	}
	.about__us {
		text-align: center;
		margin-top: 0;
	}
	.intro {
		text-align: center;
		font-size: 20px;
	}
	.unique__bapes {
		margin-left: 20px;
		margin-top: 50px;
		justify-content: center;
	}
	.unique__number {
		font-size: 60px;
	}
	.unique__text {
		font-size: 60px;
		margin-top: 0;
	}
	.road_map {
		text-align: center;
		font-size: 70px !important;
	}
	.the__team {
		font-size: 70px !important;
	}
}
@media (max-width: 540px) {
	.btn__mint {
		width: 100%;
	}
	.next__thing {
		margin-top: 700px;
		font-size: 30px !important;
	}
	.bapes {
		font-size: 120px;
	}
	.about__us {
		font-size: 40px;
	}
	.intro {
		font-size: 18px;
		padding: 20px;
	}
	.unique__bapes {
		margin-left: 0px;
	}
	.unique__number {
		font-size: 36px;
	}
	.unique__text {
		font-size: 36px;
	}
	.roadmap__section {
		background: url(/static/media/roadmap_bg.7359c6db.png);
		background-size: cover;
	}
	.road_map__phase {
		font-size: 30px !important;
	}
	.road_map__subtitle {
		font-size: 20px !important;
	}
	.hover__info p {
		font-family: 'Montserrat-Regular' !important;
		font-size: 16px;
	}
	.hover__info h4 {
		font-family: 'Montserrat-Regular' !important;
		font-size: 20px;
	}
}
@media (max-width: 420px) {
	.bape__card {
		margin-left: 30px !important;
		height: 300px;
	}
}
@media (max-width: 350px) {
	.react-multi-carousel-track {
		padding: 0 !important;
	}
	.bape__card {
		margin-left: auto !important;
		height: 300px;
	}
}

.spinner-border {
	width: 11rem !important;
	height: 11rem !important;
}

.custom_spinner.spinner-border {
	width: 2rem !important;
	height: 2rem !important;
}

